<template>
    <span>
        <span v-if="invoice.status == 'draft'" class="tc ph3 pv1 invoice-status-badge alert-warning">Draft</span>
        <span v-else-if="invoice.status?.includes('paid')" class="ml3 tc ph3 pv1 invoice-status-badge alert-success">Paid</span>
        <span
            v-else-if="invoice.status?.includes('void') || invoice.status === 'void'"
            class="tc ph3 pv1 invoice-status-badge alert-danger"
            >Voided</span
        >
        <span
            v-else-if="invoice.status?.includes('part_payment') || invoice.status === 'part_payment'"
            class="tc ph3 pv1 invoice-status-badge alert-info"
            >Part Payment</span
        >
        <span
            v-else-if="invoice.status?.includes('awaiting_payment') || invoice.status === 'awaiting_payment'"
            class="tc ph3 pv1 invoice-status-badge alert-danger"
            >Unpaid</span
        >
        <span v-else-if="invoice.status == 'pending'" class="tc ph3 pv1 invoice-status-badge alert-danger">Pending</span>
        <span v-else-if="invoice.status == 'approved'" class="tc ph3 pv1 invoice-status-badge alert-success">Approved</span>
        <span v-else></span>
    </span>
</template>
<script>
export default {
    name: 'InvoiceStatus',
    props: {
        invoice: Object
    }
}
</script>
