<template>
    <!--inventory , inport button, send statement & new -->
    <UploadInventoryModal :close-modal="toggleImportInventoryModal" />
    <div
        v-if="contentType === 'invoiceSummary'"
        class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3 mini-spacing"
    >
        <div class="pb3 pb0-l">
            <div class="f3 b">
                {{ $route.fullPath.startsWith('/invoice') ? 'Invoice' : 'Quotations' }}
                <strong
                    ><font-awesome-icon
                        icon="fa-solid fa-question-circle fa-2xs"
                        class="text-muted clickModalIcon"
                        @click="clickModalInvoice = true"
                        style="cursor: pointer"
                /></strong>
            </div>
            <div class="dashboard-inquiry-modal" v-if="clickModalInvoice">
                How does this work? Click
                <a
                    href="https://help.simplebks.com/en-gb/a/64240fe0651ea21e2a9f1146-how-to-create-an-invoice-on-simplebks"
                    target="_blank"
                    style="color: blue"
                    >here</a
                >
                to learn more.
            </div>
        </div>
        <div class="scrollmenu flex">
            <!-- <button class="btn2 ml2" @click.prevent="toggleImportInventoryModal">Export</button> -->
            <router-link 
                v-if="$route.fullPath.startsWith('/invoice') && (role === 'owner' || role === 'admin' || rolePermissions?.includes(31))"
                :to="{ name: 'InvoiceNew' }" 
                class="pr1"
            >
                <button class="ml2 flex items-center">New Invoice</button>
            </router-link>
            <router-link 
                v-if="$route.fullPath.startsWith('/quotations') && (role === 'owner' || role === 'admin' || rolePermissions?.includes(31))" 
                :to="{ name: 'CreateAQuote' }" 
                class="pr1"
            >
                <button class="ml2 flex items-center">New Quotation</button>
            </router-link>
        </div>
    </div>
    <div v-if="contentType === 'editInvoice'" class="w-90 center pv4-l pv3 mini-spacing">
        <div class="font-w1 pb0-l pb2">
            <!-- <router-link :to="{ name: 'InvoiceSummary' }" style="color: #132c8c">{{ $route.path === '/create-invoice' ? 'Invoice' : 'Quotation' }} detailssssss</router-link> -->
            <router-link v-if="$route.path === `/invoice/edit/${id}`" :to="{ name: 'Invoice' }" style="color: #132c8c">&lt; back</router-link>
            <router-link v-if="$route.path === `/quotations/${id}/edit`" :to="{ name: 'QuotePreview' }" style="color: #132c8c">&lt; back</router-link>
            
        </div>
        <div class="flex flex-wrap justify-between items-center pv2">
            <div class="flex items-center">
                <div class="f3 b">{{ $route.fullPath.startsWith('/invoice') ? 'Edit Invoice' : 'Edit Quotation' }}</div>
            </div>
            <div class="scrollmenu pt3 pt0-l">
                <button v-if="$route.fullPath.startsWith('/invoice')" @click.prevent="submit('draft')" class="btn2" :disabled="state.processing">
                    <a style="color: #132c8c">Save Invoice as Draft</a>
                </button>

                <button @click.prevent="submit('update')" class="ml2" :disabled="state.processing">
                    <a>Preview</a>
                </button>
            </div>
        </div>
    </div>

    <div v-if="contentType === 'newInvoice'" class="w-90 center pv4-l pv3 mini-spacing">
        <div class="font-w1 pb0-l pb2">
            <router-link v-if="$route.path === '/create-invoice'" :to="{ name: 'InvoiceSummary' }" style="color: #132c8c">&lt; back</router-link>
            <router-link v-if="$route.path === '/quotations/create'" :to="{ name: 'Quotations' }" style="color: #132c8c">&lt; back</router-link>
        </div>
        <div class="flex flex-wrap justify-between items-center pv2">
            <div class="flex items-center">
                <div class="f3 b">{{ $route.path === '/create-invoice' ? 'Invoice' : 'Quotation' }}</div>
            </div>
            <div class="scrollmenu pt3 pt0-l">
                <button v-if="$route.path === '/create-invoice'"
                    @click.prevent="submit('draft')"
                    class="btn2"
                    :disabled="emailVerifiedAt === null || state.processing"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    Save Invoice as Draft
                </button>
                <button v-if="$route.path === '/create-invoice'"
                    @click.prevent="submit('draft')"
                    class="btn2 ml2"
                    :disabled="emailVerifiedAt === null || state.processing"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    Save as Quotation
                </button>
                <button
                    @click.prevent="submit('send')"
                    class="ml2"
                    :disabled="emailVerifiedAt === null || state.processing"
                    :title="emailVerifiedAt === null ? 'Verify email to enable feature' : ''"
                >
                    Preview
                </button>
            </div>
        </div>
    </div>
    <div v-if="contentType === 'previewInvoice'" class="w-90 center pv4-l pv3 mini-spacing">
        <div class="flex flex-wrap justify-between items-center pv2">
            <div class="flex flex-column items-start pb3 pb0-l">
                <router-link class="pb3" v-if="$route.fullPath.startsWith(`/invoice`)" :to="{name: 'InvoiceSummary' }" style="color: #132c8c;">&lt; back to invoice summary</router-link>
                <router-link class="pb3" v-if="$route.fullPath.startsWith(`/quotations`)" :to="{name: 'Quotations' }" style="color: #132c8c;">&lt; back to quotes</router-link>
                <!-- <router-link v-if="$route.fullPath.startsWith('/quotations')" :to="{ name: 'Quotations' }" style="color: #132c8c">
                    &larr; Back to Quotation
                </router-link> -->
                <div class="f3 b">{{ $route.fullPath.startsWith('/invoice') ? 'Create and Send Invoice' : 'Create and Send Quotation' }}</div>
            </div>
            <div v-if="invoiceNumber" class="scrollmenux">
                <router-link v-if="$route.fullPath.startsWith('/invoice')" :to="{ name: 'InvoiceNew' }" style="color: #132c8c">
                    <button class="btn2 mr2">Create new invoice</button>
                </router-link>
                <router-link v-if="$route.fullPath.startsWith('/quotations')" :to="{ name: 'CreateAQuote' }" style="color: #132c8c">
                    <button class="btn2 mr2">Create New Quote</button>
                </router-link>
                <div class="dropdown" style="position: relative; display: inline-block">
                    <button
                        class="dropdown-togglex"
                        role="button"
                        id="dropdownMenuLink"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        More <font-awesome-icon icon="fa-solid fa-caret-down" class="fa-2x" />
                    </button>

                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                            <a href="#" class="dropdown-itemx"> Duplicate </a>
                        </li>
                        <li>
                            <router-link
                                :to="{ name: 'InvoiceCustomize', query: { p: 'preview', id: invoice._id } }"
                                class="dropdown-itemx"
                            >
                                Customize
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="invoiceNumberx" class="scrollmenu">
                <router-link
                    v-if="$route.fullPath.startsWith('/invoice') && !invoiceStatus?.includes('paid')"
                    :to="{ name: 'InvoiceEdit', params: { id: invoice._id } }"
                    style="color: #132c8c"
                >
                    <button v-if="invoiceStatus === 'draft'" class="btn2 ml2">Edit</button>
                </router-link>
                <router-link
                    v-if="$route.fullPath.startsWith('/quotations') && invoiceStatus === 'pending'"
                    :to="{ name: 'EditQuote', params: { id: invoice._id } }"
                    style="color: #132c8c"
                >
                    <button v-if="invoiceStatus === 'draft'" class="btn2 ml2">Edit</button>
                </router-link>
                <button
                    v-if="!invoiceStatus?.includes('paid')"
                    @click.prevent="createAndDownloadInvoice"
                    class="ml2"
                    :disabled="state.processing"
                >
                    Download and Create
                </button>
                <button
                    v-if="!invoiceStatus?.includes('paid')"
                    @click.prevent="createAndSendInvoice"
                    class="ml2"
                    :disabled="state.processing"
                >
                    Send and Create
                </button>
                <button
                    v-if="!invoiceStatus?.includes('paid')"
                    @click.prevent="createAndEditInvoice"
                    class="ml2"
                    :disabled="state.processing"
                >
                    Create
                </button>
            </div>
        </div>
    </div>
    <div v-if="contentType === 'mailInvoice'" class="w-90 center pv4-l pv3 mini-spacing">
        <div class="font-w1">
            <router-link v-if="$route.fullPath.startsWith('/invoice')" :to="{ name: 'InvoiceEdit', params: { id } }" style="color: #132c8c">Edit Invoice</router-link>
            <router-link v-if="$route.fullPath.startsWith('/quotations')" :to="{ name: 'EditQuote', params: { id } }" style="color: #132c8c">Edit Invoice</router-link>
        </div>
        <div class="flex flex-wrap justify-between items-center pv2">
            <div class="flex items-center pb3 pb0-l">
                <div class="f3 b">{{ $route.fullPath.startsWith('/invoice') ? 'Send Invoice' : 'Send Quote'}}</div>
            </div>
            <div class="scrollmenu">
                <button v-if="invoiceStatus === 'draft'" class="btn2">
                    <router-link v-if="$route.fullPath.startsWith('/invoice')" :to="{ name: 'InvoiceEdit', params: { id } }" style="color: #132c8c">Edit</router-link>
                    <router-link v-if="$route.fullPath.startsWith('/quotations')" :to="{ name: 'EditQuote', params: { id } }" style="color: #132c8c">Edit</router-link>
                </button>
                <button v-if="invoiceReceipt" @click.prevent="downloadReceipt" class="btn2 ml2" :disabled="state.processing">
                    Download
                </button>
                <button v-if="!invoiceStatus?.includes('paid')" @click.prevent="sendInvoiceAsPdf" class="ml2">Send</button>
            </div>
        </div>
    </div>
    <div v-if="contentType === 'viewInvoice'" class="w-90 center pv4-l pv3 mini-spacing">
        <div class="font-w1">
            <router-link v-if="$route.fullPath.startsWith('/invoice')" :to="{ name: 'InvoiceSummary' }"> Invoice</router-link>
            <router-link v-if="$route.fullPath.startsWith('/quotations')" :to="{ name: 'Quotations' }"> Quotations</router-link>
        </div>
        <div class="flex flex-wrap justify-between items-center pv2">
            <div class="flex items-center pb3 pb0-l">
                <div v-if="$route.path === `/invoice/${id}`" class="f3 b">Invoice #{{ invoiceNumber }}</div>
                <div v-if="$route.path === `/quotations/${id}`" class="f3 b">Quotation #{{ invoiceNumber }}</div>
                <invoice-status :invoice="invoice" />
            </div>
            <div class="scrollmenu flex">
                <button v-if="$route.fullPath.startsWith('/invoice') && invoiceStatus === 'draft'" class="btn2">
                    <router-link :to="{ name: 'InvoiceEdit', params: { id } }" style="color: #132c8c">Edit</router-link>
                </button>
                <router-link :to="'#'" @click.prevent="approveQuote(invoice)"  style="color: #132c8c">
                <button v-if="$route.fullPath.startsWith('/quotations') && (invoiceStatus === 'pending')"  class="btn2">
                        Convert to Invoice
                    </button>
                </router-link>
                <!-- <button v-if="$route.fullPath.startsWith('/quotations') && (invoiceStatus === 'draft' || invoiceStatus === 'pending')" class="btn2">
                    <router-link :to="{ name: 'EditQuote', params: { id } }" style="color: #132c8c">Edit</router-link>
                </button> -->
                <button
                    v-if="
                        (invoiceStatus?.includes('awaiting_payment') ||
                            invoiceStatus === 'awaiting_payment' ||
                            invoiceStatus?.includes('part_payment')) &&
                        invoice.totalAmount > (invoice?.deposit || 0)
                    "
                    class="btn2"
                    @click="toggleRecordPaymentModal({ status: true, invoice })"
                >
                    Record Payment
                </button>
                <!-- <button
                    v-if="invoiceStatus !== 'draft'"
                    @click.prevent="downloadReceipt"
                    class="btn2 ml2"
                    :disabled="state.processing"
                >
                    Download
                </button> -->
                <!-- <button
                    class="dropdown-togglex btn1 ml2"
                    role="button"
                    id="dropdownMenuLink2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    v-if="invoiceStatus !== 'draft'"
                >
                    Send <font-awesome-icon icon="fa-solid fa-caret-down" class="" />
                </button> -->
                <button
                    class="dropdown-togglex btn1 ml2"
                    role="button"
                    id="dropdownMenuLink2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    v-if="invoiceStatus !== 'draft'"
                >
                    More <font-awesome-icon icon="fa-solid fa-caret-down" class="" />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink2" style="cursor: pointer">
                    <router-link :to="{ name: 'EditQuote', params: { id } }"><li  class="ml2" v-if="$route.fullPath.startsWith('/quotations') && (invoiceStatus === 'draft' || invoiceStatus === 'pending')">Edit</li></router-link>
                    <li
                        v-if="invoiceStatus !== 'draft'"
                        @click.prevent="downloadReceipt"
                        class="ml2"
                        :disabled="state.processing"
                    >
                        Download as PDF
                    </li>
                    <li
                        v-if="invoiceStatus !== 'draft' && !invoiceStatus?.includes('paid')"
                        @click.prevent="sendInvoiceAsPdf"
                        class="ml2"
                    >
                        Share via Email
                    </li>
                    <li
                        v-if="
                            (invoiceStatus === 'awaiting_payment' || invoiceStatus?.includes('part_payment'))  || invoiceStatus === 'pending'  &&
                            !invoiceStatus?.includes('paid')
                        "
                        @click="openWhatsapp(invoice, shareUrl)"
                        class="ml2 flex"
                        style="gap: 3px"
                    >
                        <span>Share via Whatsapp</span><span><img src="@/assets/images/whatsapp.svg" width="15" /></span>
                    </li>
                </ul>
                <!-- <button
                    v-if="invoiceStatus !== 'draft' && !invoiceStatus?.includes('paid')"
                    @click.prevent="sendInvoiceAsPdf"
                    class="ml2 btn1"
                >
                    Send
                </button> -->
                <!-- <button
                    class="dropdown-togglex btn2 ml2"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    v-if="invoiceStatus !== 'draft'"
                >
                    More <font-awesome-icon icon="fa-solid fa-caret-down" class="" />
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink" style="cursor: pointer" v-if="invoiceStatus !== 'draft'">
                    <li
                        v-if="
                            (invoiceStatus?.includes('awaiting_payment') ||
                                invoiceStatus === 'awaiting_payment' ||
                                invoiceStatus?.includes('part_payment')) &&
                            invoice.totalAmount > (invoice?.deposit || 0)
                        "
                        class="ml2"
                        @click="toggleRecordPaymentModal({ status: true, invoice })"
                    >
                        Record Payment
                    </li>
                    <li
                        v-if="invoiceStatus !== 'draft'"
                        @click.prevent="downloadReceipt"
                        class="ml2"
                        :disabled="state.processing"
                    >
                        Download
                    </li>
                    <li @click="openWhatsapp(invoice, shareUrl)" class="flex ml2" style="gap: 3px">
                        <span>Share to Whatsapp</span><span><img src="@/assets/images/whatsapp.svg" width="15" /></span>
                    </li>
                </ul> -->
            </div>
        </div>
    </div>
    <div v-if="contentType === 'paymentInvoice'" class="w-90 center pv4-l pv3 mini-spacing">
        <div class="font-w1">
            <a href="/invoice"> {{ $route.path === '/create-invoice' ? 'Invoice' : 'Quotation' }}</a>
        </div>
        <div v-cloak class="flex flex-wrap justify-between items-center pv2">
            <div class="flex items-center pb3 pb0-l">
                <div class="f3 b">{{ $route.path === '/create-invoice' ? 'Invoice' : 'Quotation' }} #{{ invoiceNumber }}</div>
                <invoice-status :invoice="invoice" />
            </div>
            <div class="scrollmenu">
                <button @click.prevent="downloadReceipt" class="btn2 ml2" :disabled="state.processing">Download</button>
                <button v-if="!invoiceStatus?.includes('paid')" @click.prevent="sendInvoiceAsPdf" class="ml2">Send</button>
            </div>
        </div>
    </div>

    <!--inventory, import button, send statement & new -->
    <!--      Create Entity Modal / Form-->
    <CreateProduct :closeModal="() => toggleCreateProduct({ show: true, cat: 'PRODUCT' })" />

    <!-- summary all product, draft archieve -->
    <div class="w-90 center pv2">
        <div class="flex scrollmenu"></div>
    </div>
    <!-- summary all product, draft archive -->
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import CreateProduct from '@/components/CreateProduct'
import UploadInventoryModal from '@/components/UploadInventoryModal'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import { formatDate, handleDownloadPDF, htmlToBuffer } from '@/utils/lib'
import Swal from 'sweetalert2'

export default {
    name: 'InventoryHeader',
    components: { CreateProduct, UploadInventoryModal, InvoiceStatus },
    props: {
        page: String,
        type: String,
        contentType: String,
        submit: Function,
        invoiceNumber: String,
        invoiceStatus: String,
        id: String,
        invoiceForm: Object,
        invoiceTemplate: String,
        invoice: Object,
        shareUrl: String,
    },
    setup(props, { emit }) {
        const route = useRoute()
        const store = useStore()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const invoiceReceipt = computed(() => store?.state.Invoice.invoiceReceipt)
        const emailVerifiedAt = computed(() => store?.state?.Auth?.userData?.emailVerifiedAt)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const invoice = computed(() => store.state.Invoice.invoice)

        const initialState = { processing: false , invoice: null,}
        const state = reactive(initialState)

        const toggleImportInventoryModal = () => {
            store.dispatch('Inventory/toggleImportInventoryModal')
        }

        const toggleCreateProduct = (payload) => {
            store.dispatch('Inventory/showCreateProduct', payload)
        }

        const sendpdf = async () => {
            const file = await htmlToBuffer(props.invoiceForm)
            const formData = new FormData()
            formData.append('imageFile', file)
            formData.append('id', props.id)

            // dispatch invoice action
            store.dispatch('Invoice/sendInvoice', formData)
        }

        const createAndSendInvoice = () => {
            state.processing = true
            props.submit('send')
        }

        const createAndDownloadInvoice = () => {
            state.processing = true
            props.submit('download')
        }

        const createAndEditInvoice = () => {
            state.processing = true
            props.submit('create')
        }

        const sendInvoiceAsPdf = async () => {
            const storeAction = route.fullPath.startsWith('/quotations')
                ? 'Invoice/sendQuotationAsPdf'
                : 'Invoice/sendInvoiceAsPdf'

            store.dispatch(storeAction, { id: props.id, template: props.invoiceTemplate })
        }

        const downloadReceipt = () => {
            const { id } = route.params
            state.processing = true
            const storeAction = route.fullPath.startsWith('/quotations')
                ? 'Invoice/getQuotationReceiptPdf'
                : 'Invoice/getInvoiceReceiptPdf'
            store
                .dispatch(storeAction, { id, template: props.invoiceTemplate })
                .then((response) => {
                    const date = formatDate(new Date(), 'DDMMYYYY')

                    const fileURL = window.URL.createObjectURL(new Blob([response]))
                    const fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    const fileName = `${orgData.value?.org} - ${date}`
                    const fileTitle = route.fullPath.startsWith('/quotations') ? 'quotation' : 'invoice'
                    fileLink.setAttribute('download', `${fileTitle}-${props.invoiceNumber} - ${fileName}.pdf`)
                    fileLink.setAttribute('target', '_blank')
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    fileLink.remove()
                    state.processing = false
                })
                .catch(() => (state.processing = false))
        }

        const payInvoice = (invoice) => {
            emit('on-pay-invoice', invoice)
        }

        const clickModalInvoice = ref(false)

        const closeModal = () => {
            clickModalInvoice.value = false
        }
        const toggleRecordPaymentModal = (payload) => {
            store.dispatch('Invoice/showRecordPaymentModal', payload)
        }

        const openWhatsapp = (inv, shareUrl) => {
            const phoneNumber = inv.customer.phone.replace(/\D/g, '').slice(-10);
            const message = `
Hi ${inv.customer.name.split(' ')[0]},
Your invoice ${inv.reference} for the sum of ${inv.totalAmount}${inv.currency} on ${inv.dateIssued} from ${
                inv.business.name
            } is ready for you. View it here: ${shareUrl}
If you have any questions, contact us at ${inv.business.email} or ${inv.business.phone}.
Thanks for choosing ${inv.business.name}.
Best regards, ${inv.business.name}`
            const encodedMessage = encodeURIComponent(message).replace(/%0A/g, '%0A%0A')
            const whatsappURL = `https://wa.me/+234${phoneNumber}?text=${encodedMessage}`
            window.open(whatsappURL, '_blank')
        }


        const processInvoiceStatus = (invoice) => {
            if (invoice.initial_deposit === invoice.totalAmount) {
                invoice.status = ['paid']
            } else if (invoice.initial_deposit > 0) {
                invoice.status = ['part_payment']
            } else {
                invoice.status = 'awaiting_payment'
            }

            return invoice
        }

        const approveQuote = quote => {
            Swal.fire({
                title: `Approve Quotation`,
                text: `Are you sure you want to approve this quote - ${invoice.reference}?`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Yes, Approve!',
                denyButtonText: `No, cancel!`
            }).then(result => {
                if (result.isConfirmed) {
                    const invoice = processInvoiceStatus(state.invoice)
                    invoice.status = 'awaiting_payment'
                    store.dispatch('Invoice/createAndSendInvoice', invoice).then(() => {
                        invoice.status = 'approved'
                        store.dispatch('Invoice/approveQuote', invoice._id)
                    })
                }
            })
        }

        onMounted(async () => {
            const storeAction = route.fullPath.startsWith('/quotations')
                ? 'Invoice/getQuotation'
                : 'Invoice/getInvoice'

            await store.dispatch(storeAction, route.params.id).then((data) => {
                state.invoice = data
            })
        })

        onMounted(() => {
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (
                    !(event.target.closest('.clickModalIcon') || event.target === modalIcon) &&
                    clickModalInvoice.value === true
                ) {
                    closeModal()
                }
            })
        })

        return {
            state,
            toggleCreateProduct,
            toggleImportInventoryModal,
            handleDownloadPDF,
            htmlToBuffer,
            downloadReceipt,
            invoiceReceipt,
            sendpdf,
            sendInvoiceAsPdf,
            createAndSendInvoice,
            createAndDownloadInvoice,
            createAndEditInvoice,
            emailVerifiedAt,
            payInvoice,
            rolePermissions,
            role,
            toggleRecordPaymentModal,
            clickModalInvoice,
            closeModal,
            openWhatsapp,
            approveQuote,
        }
    },
}
</script>

<style>
.custom-active {
    color: #132c8c;
    display: inline-block;
}

.invoice-status-badge {
    /* background: #FEEAD7;  */
    /* color: #FB8B24;  */
    border-radius: 20px;
}
</style>
